<template>
  <div>
    <v-card>
      <v-card-text>
        <h2>ເພີ່ມລະຫັດສ່ວນຫຼຸດໃຫ້ລູກຄ້າ</h2>
        <br>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col>
                <v-select
                    required
                    v-model="selectedPromotion"
                    :items="promotions"
                    item-text="name"
                    item-value="id"
                    label="ເລືອກລະຫັດສ່ວນຫຼຸດ *"
                    outlined
                    dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                    required
                    v-model="selectedDistrict"
                    :items="districts"
                    item-text="name"
                    item-value="id"
                    label="ເລືອກເມືອງ *"
                    outlined
                    dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                    required
                    v-model="selectedCustomer"
                    :items="customers"
                    item-text="name"
                    item-value="id"
                    label="ເລືອກລູກຄ້າ *"
                    outlined
                    dense
                    multiple
                    :loading="loading"
                    :disabled="loading"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
<!--            <v-row>-->
<!--              <v-col cols>-->
<!--                  <v-menu-->
<!--                      v-model="expired_menu"-->
<!--                      :close-on-content-click="true"-->
<!--                      :nudge-right="40"-->
<!--                      transition="scale-transition"-->
<!--                      offset-y-->
<!--                      min-width="auto"-->
<!--                  >-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                      <v-text-field-->
<!--                          v-model="expired_date"-->
<!--                          label="ວັນໝົດອາຍຸ"-->
<!--                          readonly-->
<!--                          outlined-->
<!--                          v-bind="attrs"-->
<!--                          v-on="on"-->
<!--                          dense-->
<!--                      ></v-text-field>-->
<!--                    </template>-->
<!--                    <v-date-picker-->
<!--                        v-model="expired_date"-->
<!--                    ></v-date-picker>-->
<!--                  </v-menu>-->
<!--                <p class="error&#45;&#45;text">{{server_errors.expired_date}}</p>-->
<!--              </v-col>-->
<!--            </v-row>-->
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Description"
                    v-model="promotion.description"
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="Close()">
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="saveLoading"
              :disabled="saveLoading"
              @click="Save()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// import manage from "@/mixins/platform/manage_promotion";
export default {
  // mixins: [manage],
  data(){
    return {

      saveLoading:false,
      loading:false,
      promotions:[],
      promotion:{},
      selectedPromotion:'',
      customers:[],
      selectedCustomer:[],
      districts:[],
      selectedDistrict:'',
    }
  },
  methods:{
    fetchPromotion() {
      this.$axios.get('promotions').then(res => {
        this.promotions = res.data.data;
      }).catch(() => {
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    fetchCustomer() {
      this.loading = true;
      this.$axios.get('filter-customers/'+ this.selectedDistrict).then(res => {
        this.customers = res.data.customers;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    fetchDistrict() {
      this.$axios.get('list-districts').then(res => {
        this.districts = res.data.listDistricts;
      }).catch(() => {
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    Save(){
      if (this.$refs.form.validate() == true) {
        const data = {
          promotion_id: this.selectedPromotion,
          customer_id: this.selectedCustomer,
          description: this.promotion.description
        };
        this.saveLoading = true;
        this.$admin.post('/user/promotion/add', data)
            .then(res => {
              if (res.status == 200) {
                setTimeout(() => {
                  this.saveLoading = false;
                  this.promotion = {},
                      this.$router.go(-1);
                }, 300);
              }
            }).catch(error => {
          this.saveLoading = false;
          if (error.response.status == 422) {
            const obj = error.response.data.errors;
            for (let [key, user] of Object.entries(obj)) {
              this.server_errors[key] = user[0];
            }
            this.$store.commit("Toast_State", this.toast_error);
          }
        });
      }
    },
    Close(){
      this.$router.go(-1);
    }
  },
  watch:{
    selectedDistrict:function (){
      this.fetchCustomer();
    }
  },
  created() {
    this.fetchPromotion();
    this.fetchDistrict();
    this.fetchCustomer();
  }
};
</script>

<style>
.primary-color {
  color: #00c1d2;
}

@import "../../../../public/scss/main.scss";
</style>